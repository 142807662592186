import React from 'react'
import { graphql } from 'gatsby'
import { Head } from 'src/components/Layout'
import { getLayouts } from 'src/lib/layout/getLayouts'

interface PageProps {
  errors?: any
  data?: GatsbyTypes.WpPageQuery
}

export default function Page({ data, errors }: PageProps): JSX.Element {
  errors && console.error(process.env.NODE_ENV === 'development' && errors)

  return (
    <>
      <Head
        meta={data?.wpPage?.ACF?.meta}
        page={{
          __typename: 'WpPage',
          title: data?.wpPage?.title || '',
          uri: data?.wpPage?.uri || '',
        }}
      />
      {getLayouts(data?.wpPage?.ACF?.layouts)}
    </>
  )
}

export const pageQuery = graphql`
  query WpPage($id: String!) {
    wpPage(id: { eq: $id }) {
      __typename
      id
      title
      uri
      isFrontPage
      isPostsPage
      isPrivacyPage
      ACF {
        ...MetaFields
        ...LayoutFields
        ...OptionsFields
      }
    }
  }
`
